import { useState } from "react";
import { useWalletConnect } from "../../../../CustomHook/useWalletConnect";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import CommonModal from "../CommonModal/CommonModal";
import "./WithdrawModal.scss";
import { cryptoDecimals, validateInputField } from "../../../../utils/helpers";
import { userWithdraw } from "../../../../services/stakingServices/stakingHelper";
import TxnModal from "../TxnModal/TxnModal";

type TProps = {
  handleClose: () => void;
  show?: boolean;
  withdrawData?: any;
  fetchLatestData: any;
  transactionModal: any;
  transactionModalData: any;
};

const WithdrawModal = (props: TProps) => {



  const dispatch = useAppDispatch();
  const { walletProvider } = useWalletConnect();
  const { walletAddress }: { walletAddress: string } = useAppSelector(
    (store: any) => store?.user
  );
  const [depositInput, setDepositInput] = useState({
    inputValue: "",
    convertedValue: "",
  });

  const emptyValues = () => {
    setDepositInput({
      convertedValue: "",
      inputValue: "",
    });
  };
  const handleWithdraw = async () => {
    props?.handleClose();
    const response = await userWithdraw({
      tokenAddress: props?.withdrawData?.tokenAddress,
      proxyAddress: props?.withdrawData?.chefProxy,
      walletProvider,
      walletAddress,
      dispatch,
      fee: props?.withdrawData?.platformfee,
      stakeType: props?.withdrawData?.pid,
      inputValue: depositInput?.convertedValue,
      setModalData: props?.transactionModalData,
    });
    if (response) {
      await props?.fetchLatestData();
    }
  };
  const handleWithdrawInput = async (e: string, max: boolean) => {
    const response: boolean | string = await validateInputField(
      e,
      18,
      max,
      emptyValues
    );
    if (response) {
      let convertedValue: string = (
        max ? response : Number(response) * 10 ** 18
      ).toLocaleString("fullwide", {
        useGrouping: !1,
      });

      let originalValue: string = (
        max && response.toString()?.length > 14
          ? cryptoDecimals(Number(response) / 10 ** 18)
          : max && response.toString()?.length < 14
          ? (Number(response) / 10 ** 18).toString()
          : response
      ).toLocaleString("fullwide", {
        useGrouping: !1,
      });
      setDepositInput({
        convertedValue: convertedValue,
        inputValue: originalValue,
      });
    }
  };
  const insufficientBalance =
    Number(props?.withdrawData?.userStaked) <
    Number(depositInput?.convertedValue);

  return (
    <CommonModal
      show={props.show}
      handleClose={props.handleClose}
      className="withdraw_modal"
      heading={`Withdraw (${props?.withdrawData?.lpName} LP Tokens)`}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <Input
          label={
            <>
              <span>
                {cryptoDecimals(props?.withdrawData?.userStaked / 10 ** 18)}
              </span>
              &nbsp; Staked LP Amount
            </>
          }
          type="number"
          value={depositInput?.inputValue}
          onChange={(e: any) => handleWithdrawInput(e.target.value, false)}
          placeholder={props?.withdrawData.lpName}
          rightIcon={
            <button
              className="max_btn"
              onClick={() =>
                handleWithdrawInput(props?.withdrawData?.userStaked, true)
              }
            >
              MAX
            </button>
          }
        />
        <div className="action_btn">
          <Button
            fluid
            className="gradientBtnlight"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            fluid
            type="submit"
            onClick={() => {
             
              handleWithdraw();
              props?.transactionModal(true);
            }}
            disabled={
              insufficientBalance || Number(depositInput?.inputValue) == 0
            }
          >
            Confirm
          </Button>
        </div>
      </form>
    </CommonModal>
  );
};

export default WithdrawModal;
