import { useEffect, useState } from "react";
import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap";
import EthIcon from "../../../assets/icons/EthIcon.svg";
import {
  ArrowdownGrayIcon,
  DownArrowIcon,
  InfoBlackIcon,
  InfoIcon,
  SearchIcon,
  ShareIcon,
} from "../../../assets/icons/svgicons";
import Button from "../../common/Button/Button";
import Card from "../../common/Card/Card";
import CommonTable from "../../common/CommonTable/CommonTable";
import Commonselect from "../../common/Select/Commonselect";
import SettingOverlay from "../../common/SettingOverlay/SettingOverlay";
import SecondaryTokenCard from "../../common/TokenCard/SecondaryTokenCard";
import { StakeTab } from "./StakingComponent/StakeTab";
import { UnstakeTab } from "./StakingComponent/UnstakeTab";
import "./StakingPool.scss";
import SaitaRealty_Icon from "../../../assets/icons/SaitaReality.svg";
import accountIcon from "../../../assets/icons/accountIcon.svg";
import BlueSparrow from "../../../assets/icons/blue-sparrow.svg";
import stcIcon from "../../../assets/icons/stcIcon.svg";
import { useAppSelector } from "../../../app/hooks";
import { getStakingData } from "../../../services/ApiServices/apiService";
import { cryptoDecimals, customizeAddress } from "../../../utils/helpers";
import ConnectWallet from "../../common/Header/ConnectWallet/ConnectWallet";
import { TOKEN_DATA } from "../../../interfaces/Liquidity";
import moment from "moment";
import { Link } from "react-router-dom";
import Shimmer from "../../common/Shimmer/Shimmer";
import SwitchToggle from "../../common/SwitchToggle/SwitchToggle";

var oldChainVal = "";
var hasChangedChainVal = false;

const StakingPool = () => {
  const {
    walletAddress,
    chainValues,
  }: { walletAddress: string; chainValues: any } = useAppSelector(
    (store: any) => store?.user
  );
  const { tokenList }: { tokenList: TOKEN_DATA[] } = useAppSelector(
    (store: any) => store?.token
  );
  const [isSearchedTriggered, setIsSearchedTriggered] =
    useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [stakingDetails, setStakingDetails] = useState<any>([]);
  const [numPoolsToShow, setNumPoolsToShow] = useState(8);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [totalCount, setTotalCount] = useState(0);
  const [showConnectWallet, setConnectWallet] = useState<boolean>(false);
  const [showUserStakeData, setShowUserStakeData] = useState<any>({
    data: {},
    key: 0,
  });
  const [filteredStakingDetails, setFilteredStakingDetails] = useState<any>([]);
  const [expandView, setExpandView] = useState<boolean>(false);

  useEffect(() => {
    hasChangedChainVal = false;
    fetchStakeData();
    setExpandView(false);
  }, [page]);

  useEffect(() => {
    setPage(1);
    if (page === 1) {
      hasChangedChainVal = true;
      fetchStakeData();
    }
  }, [chainValues]);

  const searching = (key: string) => {
    if (key !== "") {
      const filteredList = JSON.parse(JSON.stringify(stakingDetails));
      const results = filteredList.filter((tokenInfo: any) => {
        return tokenInfo?.tokenName.toLowerCase().includes(key.toLowerCase());
      });
      setFilteredStakingDetails(results);
      setIsSearchedTriggered(true);
    } else {
      setFilteredStakingDetails([]);
      setIsSearchedTriggered(false);
    }
  };

  const fetchStakeData = async () => {
    // const newVal = `${chainValues?.symbol}${chainValues?.chainId}`;
    // if (oldChainVal == newVal && hasChangedChainVal) return;
    // else {
    //   oldChainVal = newVal;
    // }

    setStakingDetails([]);
    const { result, totalCount }: any = await getStakingData(
      chainValues?.symbol == "ETH"
        ? "ETHEREUM"
        : chainValues?.symbol == "BSC"
        ? "BINANCE"
        : "SAITACHAIN",
      walletAddress ?? "",
      page,
      pageSize
    );

    const updatedStakingData = await result?.map((item: any, key: any) => {
      if (item?.userData?.length > 0) {
        item.userModalDetails = item?.userData.map((value: any, index: any) => {
          value.finalReward = item?.finalReward[index];
          value.rewardCalculated = item?.rewardCalculated[index];
          value.lockedTill =
            item?.userData?.length > 0
              ? moment
                  .unix(Number(value[1]) + item?.stakePeriod * 60)
                  .format("DD MMM YYYY")
              : null;
          return value;
        });
      }
      return item;
    });
    setStakingDetails(
      page === 1
        ? updatedStakingData
        : [...stakingDetails, ...updatedStakingData]
    );
    setTotalCount(totalCount);
    if (Object.entries(showUserStakeData?.data).length != 0) {
      setShowUserStakeData({
        data: updatedStakingData[showUserStakeData?.key],
        key: showUserStakeData?.key,
      });
    }
  };

  const handleStakeOnly = (toggleVal: boolean) => {
    if (toggleVal) {
      const stakedData = (
        filteredStakingDetails?.length > 0
          ? filteredStakingDetails
          : stakingDetails
      )?.filter((item: any) => item?.totalStakedIn > 0);
      setFilteredStakingDetails(stakedData);
    } else {
      setFilteredStakingDetails(stakingDetails);
    }
  };

  const handleViewMore = () => {
    setNumPoolsToShow(numPoolsToShow + 8);
  };

  const formatStakedValue = (value: any, dec: any) => {
    let v = value || 0;
    let d = dec || 18;
    v = +v / 10 ** +d;

    let factor = 1,
      suf = "";
    if (v >= 1000000000000) {
      factor = 1000000000000;
      suf = "T";
    } else if (v >= 1000000000) {
      factor = 1000000000;
      suf = "B";
    } else if (v >= 1000000) {
      factor = 1000000;
      suf = "M";
    } else if (v >= 1000) {
      factor = 1000;
      suf = "K";
    }
    return `${cryptoDecimals(v / factor)}${suf}`;
  };

  return (
    <>
      <section className="staking_pool">
        {!(showUserStakeData?.data && expandView) && (
          <div className="staking_header">
            <SwitchToggle
              label="Staked Only"
              onClick={() => {
                setToggle(!toggle);
                handleStakeOnly(!toggle);
              }}
            />
            <div className="search_input">
              <span>
                <SearchIcon />
              </span>
              <input
                type="text"
                placeholder="Search"
                onChange={(e: any) => searching(e.target.value)}
              />
            </div>
          </div>
        )}
        {showUserStakeData?.data && expandView && (
          <Row className="mt-5">

<Col lg={6} className="order-lg-1">
              <div className="addCardBox">
                <div className="addCard">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="stake"
                  >
                    <Nav>
                      <Nav.Item>
                        <Nav.Link eventKey="stake">Stake</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="unstake">Unstake</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="stake">
                        <StakeTab
                          tokenData={showUserStakeData?.data}
                          refreshData={fetchStakeData}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="unstake">
                        <UnstakeTab
                          tokenData={showUserStakeData?.data}
                          refreshData={fetchStakeData}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </Col>
            <Col lg={6} >
              <div className="staking_pool_faq">
                <h2>Staking Pool</h2>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span>Select Stake Token</span> <ArrowdownGrayIcon />
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>The user Selects the Token they wish to get staked.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span>Enter Stake Amount</span> <ArrowdownGrayIcon />
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        The user enters the amount of token they want to get
                        staked.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span>Approval Check (If Needed)</span>{" "}
                      <ArrowdownGrayIcon />
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        If the user stake token for the first time, they must
                        approve the contract to lock the tokens
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span>Stake Tokens</span> <ArrowdownGrayIcon />
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        The user confirms the transaction for staking the
                        respective tokens in the contract and the tokens get
                        lock in the contract for the time period of that pool
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <span>Unstake Tokens</span> <ArrowdownGrayIcon />
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        The user can unstake tokens after the time period is
                        over and can earn the rewards generated from staking.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          </Row>
        )}

        <div className="cardSection">
          (
            <div className="cardSection_inner">
              {(isSearchedTriggered || toggle
                ? filteredStakingDetails
                : stakingDetails
              )?.length > 0 ? (
                <Row>
                  {(isSearchedTriggered || toggle
                    ? filteredStakingDetails
                    : stakingDetails
                  )
                    .slice(0, expandView ? 4 : undefined)
                    ?.map((data: any, key: any) =>
                      data?.isDeleted == true ? (
                        ""
                      ) : (
                        <Col lg={3} md={4} sm={6}>
                          <div className="CardField">
                            <div className="CardField_tophead">
                              <img src={data.tokenLogo} alt="tokenIcon" />
                              <Link
                                target="_blank"
                                to={`${chainValues?.explorerUrl}/address/${data?.stakingAddress}`}
                              >
                                <div className="CardField_tophead_textSec">
                                  <h6>{data.tokenName}</h6>
                                  <p>
                                    {customizeAddress(data.stakingAddress)}{" "}
                                    <ShareIcon />
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <ul className="CardField_list" key={key}>
                              <li>
                                <label>Lock</label>
                                <p>
                                  {data?.stakePeriod / 1440}{" "}
                                  {data?.stakePeriod / 1440 <= 1
                                    ? "Day"
                                    : "Days"}
                                </p>
                              </li>
                              <li>
                                <label>APR</label>
                                <p>{data.rewardRate}%</p>
                              </li>
                              <li>
                                <label>Total Staked</label>
                                <p>
                                  {/* {cryptoDecimals(
                                      data?.totalStakedIn / data?.tokenDecimal || "0"
                                    )}{" "}
                                    {data?.tokenSymbol} */}
                                  {formatStakedValue(
                                    data?.totalStakedIn,
                                    data?.tokenDecimal
                                  )}
                                  {"  "} {data?.tokenSymbol}
                                </p>
                              </li>
                              <li>
                                <label>Withdraw fees</label>
                                <p>{data?.withdrawalsFees / 100}</p>
                              </li>
                              <li>
                                <label>Deposit fees</label>
                                <p>{data?.depositFees / 100}</p>
                              </li>
                            </ul>
                            <Button
                              className="btnSize"
                              fluid
                              text={walletAddress ? "Select" : "Connect Wallet"}
                              onClick={() => {
                                if (walletAddress) {
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                  setShowUserStakeData({
                                    data: data,
                                    key: key,
                                  });
                                  setExpandView(true);
                                } else setConnectWallet(true);
                              }}
                            />
                          </div>
                        </Col>
                      )
                    )}
                  {!expandView && +stakingDetails.length < +totalCount && (
                    <div className="cardViewBtn">
                      <Button
                        className="btnSize"
                        fluid
                        text="View More"
                        onClick={() => setPage(page + 1)}
                      />
                    </div>
                  )}
                </Row>
              ) : stakingDetails == undefined ||
                (filteredStakingDetails?.length == 0 &&
                  (toggle || isSearchedTriggered)) ? (
                <h1>No record Found</h1>
              ) : (
                <Row className="shimmer_row">
                  {Array.from({ length: 12 })?.map((item) => (
                    <Col lg={3} md={4} sm={6}>
                      <Shimmer height={234} fluid />
                    </Col>
                  ))}
                </Row>
              )}

              {!expandView || stakingDetails?.length <= 4 ? (
                ""
              ) : (
                <div className="cardViewBtn">
                  <Button
                    className="btnSize"
                    fluid
                    text="View More"
                    onClick={() => setExpandView(false)}
                  />
                </div>
              )}
              <ConnectWallet
                show={showConnectWallet}
                handleClose={() => setConnectWallet(false)}
              />
            </div>
          )
        </div>
      </section>
    </>
  );
};

export default StakingPool;
